import { FilePdfFilled, LoadingOutlined } from '@ant-design/icons'
import { FileOpener } from '@capacitor-community/file-opener'
import { Capacitor } from '@capacitor/core'
import { Directory, Filesystem } from '@capacitor/filesystem'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Button } from 'antd'
import React, { useState } from 'react'

const ContractDownloadLink = ({ contractId, institutionId }: { contractId: string; institutionId: string }) => {
  const { i18n } = useLingui()
  const filename = t({ message: 'Betreuungsvertrag' }) + `.pdf`
  const [downloading, setDownloading] = useState(false)

  const downloadContract = async () => {
    setDownloading(true)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_LEOBA_SERVER}/api/common/${institutionId}/contract-download?contractId=${contractId}&locale=${i18n.locale}`,
        {
          headers: { 'Content-Type': 'application/pdf' },
        }
      )
      const contract = await response.blob()
      return contract
    } finally {
      setDownloading(false)
    }
  }

  const handleClickOnDownload = async () => {
    const blob = await downloadContract()
    if (Capacitor.isNativePlatform()) {
      openPdfOnNativePlatform(blob, filename)
    } else {
      const contractUrl = URL.createObjectURL(blob)
      window.open(contractUrl, '_blank')
    }
  }

  return (
    <React.Fragment>
      <Button type="link" onClick={handleClickOnDownload}>
        {downloading ? <LoadingOutlined /> : <FilePdfFilled />} <u>{filename}</u>
      </Button>
    </React.Fragment>
  )
}

export default ContractDownloadLink

const openPdfOnNativePlatform = (contractBlob: Blob, filename: string) => {
  const reader = new FileReader()
  reader.onloadend = async function () {
    const base64data = reader.result as string
    await Filesystem.writeFile({
      path: filename,
      data: base64data,
      directory: Directory.Cache,
    })
    const getUriResult = await Filesystem.getUri({
      directory: Directory.Cache,
      path: filename,
    })
    const path = getUriResult.uri
    FileOpener.open({ filePath: path, contentType: 'application/pdf' })
  }
  reader.readAsDataURL(contractBlob)
}
